import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    info: null
  },
  reducers: {
    setUserInfo(state, action) {
      state.info = action.payload;
    }
  }
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
