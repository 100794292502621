export default {
  translations: {
    walletCurrencyTitle:
      "Waluta twojego Portfela: <0>{{currencyName}} - {{currencySymbol}}</0> do wykorzystania tylko w <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Twój Portfel będzie dostępny wyłącznie do zakupów w <1>{{storeName}} Ubisoft Store</1> a w transakcjach będzie używać waluty – <0>{{currencyName}} ({{currencySymbol}})</0>",
    walletCurrencyTitle_sharedCurrency:
      "Twój Portfel będzie denominowany w <0>{{currencyName}} - {{currencySymbol}}</0> i dostępny do zakupów wyłącznie w <1>sklepie w którym ceny są wyrażone w {{currencyName}}</1>",
    geoRestrictedWarning:
      "Zauważyliśmy że kraj w którym obecnie przebywasz to <0>{{countryName}}</0>, dlatego nie możesz użyć swojego Portfela na obecnym Ubisoft Store.",
    confirmText:
      "Potwierdzam, że w celu <0>zmiany waluty mojego Portfela</0> muszę skontaktować się z Ubisoft i <1>stracę pozostające fundusze</1> w Portfelu.",
    buttonText: "Aktywuj portfel",
    walletCreationError:
      "Podczas próby potwierdzenia Portfela wystąpił błąd. Odczekaj chwilę i spróbuj ponownie.",
    storeName: {
      at: "Austria",
      br: "Brazylia",
      ca: "Kanada",
      cn: "Chiny",
      de: "Niemcy",
      es: "Hiszpania",
      fr: "Francja",
      it: "Włochy",
      jp: "Japonia",
      kr: "Republika Korei",
      nl: "Holandia",
      ru: "Federacja Rosyjska",
      tr: "Turcja",
      uk: "Wielka Brytania",
      us: "Stany Zjednoczone",
      sea: "Azja Południowa",
      eu: "Europejskim",
      ie: "Obszar międzynarodowy",
      anz: "Australia-Nowa Zelandia"
    }
  }
};
