export default {
  translations: {
    walletCurrencyTitle:
      "Votre Porte-monnaie sera en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>et utilisable uniquement en <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Votre Porte-monnaie sera en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>et utilisable uniquement sur le <1>{{storeName}} Ubisoft Store</1>",
    walletCurrencyTitle_sharedCurrency:
      "Votre Porte-monnaie sera en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>et utilisable uniquement sur <1>le(s) site(s) web où les prix sont fixés en {{currencyName}}</1>",
    geoRestrictedWarning:
      "Nous avons remarqué que vous vous trouvez actuellement en <0>{{countryName}}</0>. Par conséquent, vous ne pourrez pas utiliser votre Porte-monnaie dans la boutique Ubisoft actuelle.",
    confirmText:
      "J'accepte que si je souhaite <0>changer la devise de mon Porte-monnaie</0>, je devrai contacter Ubisoft et, ce faisant, je <1>perdrai tous les fonds restants</1> dans mon Porte-monnaie.",
    buttonText: "Activer mon porte-monnaie",
    walletCreationError:
      "Une erreur s'est produite lors de la confirmation de votre Porte-monnaie. Veuillez réessayer plus tard.",
    storeName: {
      at: "Austria",
      br: "Brasil",
      ca: "Canada",
      cn: "China",
      de: "Allemagne",
      es: "Espagne",
      fr: "France",
      it: "Italie",
      jp: "Japon",
      kr: "Corée",
      nl: "Pays-Bas",
      ru: "Russie",
      tr: "Turkey",
      uk: "Royaume Uni",
      us: "United States",
      sea: "Asie du Sud Est",
      eu: "Europe",
      ie: "International",
      anz: "Australie/ Nouvelle Zélande"
    }
  }
};
