export default {
  translations: {
    walletCurrencyTitle:
      "Il tuo Wallet sarà in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>e utilizzabile solo in <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Il tuo Wallet sarà in <0>{{currencyName}} - {{currencySymbol}}</0> <br/> e utilizzabile solo sull'<1>{{storeName}} Ubisoft Store</1>",
    walletCurrencyTitle_sharedCurrency:
      "Il tuo Wallet sarà in <0>{{currencyName}} - {{currencySymbol}}</0> <br/> e utilizzabile solo sull'<1>store(s) dove i prezzi sono espressi in {{currencyName}}</1>",
    geoRestrictedWarning:
      "Notiamo che al momento sei in <0>{{countryName}}</0>, quindi non ti sarà possibile utilizzare il tuo Wallet sull'Ubisoft Store attuale.",
    confirmText:
      "Comprendo che se voglio <0>cambiare la valuta del mio Wallet</0>, dovrò contattare Ubisoft e facendo questo <1>perderò eventuale credito avanzato</1> sul mio Wallet.",
    buttonText: "Attiva il mio Wallet",
    walletCreationError:
      "Si è verificato un errore durante la conferma del tuo Wallet. Ti chiediamo di riprovare più tardi.",
    storeName: {
      at: "Austria",
      br: "Brasil",
      ca: "Canada",
      cn: "China",
      de: "Germania",
      es: "Spania",
      fr: "Francia",
      it: "Italia",
      jp: "Giappone",
      kr: "Corea",
      nl: "Paesi Bassi",
      ru: "Russian Federation",
      tr: "Turkey",
      uk: "Regno Unito",
      us: "United States",
      sea: "Sud Est Asiatico",
      eu: "Europa",
      ie: "Internazionale",
      anz: "Australia /Nuova Zelanda"
    }
  }
};
