import PropTypes from "prop-types";

export const CustomAppPropTypes = {
  onCloseModal: PropTypes.func,
  onConfirm: PropTypes.func,
  onReady: PropTypes.func
};

export const CustomAppDefaultProps = {
  onCloseModal: () => {},
  onConfirm: () => {},
  onReady: () => {}
};

export const CustomAppEventsConfigs = {};
