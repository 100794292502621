export default {
  translations: {
    walletCurrencyTitle:
      "Je Wallet wordt in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>en alleen te gebruiken in <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Je Wallet bevindt zich in <0>{{currencyName}} - {{currencySymbol}}</0> <br/> en is alleen bruikbaar in de <1>{{storeName}} Ubisoft Store</1>",
    walletCurrencyTitle_sharedCurrency:
      "Je Wallet bevindt zich in <0>{{currencyName}} - {{currencySymbol}}</0> <br/> en is alleen bruikbaar in <1>winkel(s) waar de prijzen zijn ingesteld in {{currencyName}}</1>",
    geoRestrictedWarning:
      "We hebben opgemerkt dat jij je momenteel in <0>{{countryName}}</0> bevindt, daarom kan je je Wallet niet gebruiken op de huidige Ubisoft Store.",
    confirmText:
      "Ik ga ermee akkoord dat als ik <0>mijn Wallet valuta wil wijzigen</0>, ik contact moet opnemen met Ubisoft en daardoor <1>het resterende saldo in mijn Wallet zal verliezen</1>.",
    buttonText: "Activeer mijn Wallet",
    walletCreationError:
      "Bij het bestigen van je Wallet is er een fout opgetreden. Probeer het later opnieuw.",
    storeName: {
      at: "Oostenrijk",
      br: "Brazilië",
      ca: "Canada",
      cn: "China",
      de: "Duitsland",
      es: "Spanje",
      fr: "Frankrijk",
      it: "Italië",
      jp: "Japan",
      kr: "Korea, Republic of",
      nl: "Nederland",
      ru: "Rusland",
      tr: "Turkije",
      uk: "Verenigd Koninkrijk",
      us: "United States",
      sea: "Zuid-Oost Azië",
      eu: "Europa",
      ie: "een ongedefinieerd land",
      anz: "Australië / Nieuw Zeeland"
    }
  }
};
