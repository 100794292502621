import { createSlice } from "@reduxjs/toolkit";
import { createWallet as createWalletEvent } from "@mooncake/mooncake-gateway-ecom/events/wallet";

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    wallet: {}
  },
  reducers: {
    setWallet(state, action) {
      state.wallet = action.payload;
    }
  }
});

export const createWallet = (country, currency) => dispatch => {
  return new Promise((resolve, reject) => {
    createWalletEvent(
      {
        country,
        currency
      },
      ({ resource, error }) => {
        if (error) {
          console.error(error);
          reject(error);
          return;
        }
        dispatch(setWallet(resource));
        resolve(resource);
      }
    )();
  });
};

export const { setWallet } = walletSlice.actions;

export default walletSlice.reducer;
