export const currencyStoreMapping = {
  ARS: ["br"],
  AUD: ["anz"],
  BRL: ["br"],
  CAD: ["ca"],
  CLP: ["br"],
  CNY: ["cn"],
  COP: ["br"],
  CRC: ["br"],
  CZK: ["eu"],
  DKK: ["eu"],
  EUR: ["eu", "ie", "fr", "de", "es", "at", "it", "nl"],
  GBP: ["uk"],
  HKD: ["sea"],
  HUF: ["eu"],
  IDR: ["sea"],
  JPY: ["jp"],
  KRW: ["kr"],
  MXN: ["br"],
  MYR: ["sea"],
  NOK: ["ie"],
  NZD: ["anz"],
  PEN: ["br"],
  PHP: ["sea"],
  PLN: ["eu"],
  RUB: ["ru"],
  SEK: ["eu"],
  SGD: ["sea"],
  THB: ["sea"],
  TRY: ["tr"],
  TWD: ["sea"],
  USD: ["br", "ie", "sea", "us"],
  UYU: ["br"],
};
