export default {
  translations: {
    walletCurrencyTitle:
      "Sua Carteira estará em <0>{{currencyName}} - {{currencySymbol}}</0> <br/>e só poderá ser usada no país <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry: "",
    walletCurrencyTitle_sharedCurrency: "",
    geoRestrictedWarning:
      "Percebemos que você está no <0>{{countryName}}</0>, então não poderá usar sua Carteira na Ubisoft Store.",
    confirmText:
      "Eu concordo que, se quiser <0>mudar a moeda da Carteira</0>, terei que entrar em contato com a Ubisoft e, ao fazer isso, <1>perderei todos os fundos restantes</1> na Carteira.",
    buttonText: "Ativar minha carteira",
    walletCreationError:
      "Ocorreu um erro ao confirmar sua Carteira. Tente novamente mais tarde.",
    storeName: {
      at: "Austria",
      br: "Brasil",
      ca: "Canada",
      cn: "China",
      de: "Germany",
      es: "Spain",
      fr: "France",
      it: "Italy",
      jp: "Japan",
      kr: "Korea, Republic of",
      nl: "Netherlands",
      ru: "Russian Federation",
      tr: "Turkey",
      uk: "United Kingdom",
      us: "United States",
      sea: "South Asia",
      eu: "Europe",
      ie: "International",
      anz: "Australia-New Zealand"
    }
  }
};
