export default {
  translations: {
    walletCurrencyTitle:
      "Tu Wallet se mostrará en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>y solo se puede usar en <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Tu Wallet está en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>y solo se puede usar en <1>{{storeName}} Ubisoft Store</1> ",
    walletCurrencyTitle_sharedCurrency:
      "Tu Wallet está en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>y solo se puede usar en <1>store(s) donde los precios están establecidos {{currencyName}}</1>",
    geoRestrictedWarning:
      "Te encuentras en <0>{{countryName}}</0>, por lo tanto, no podrás usar tu Wallet en la Ubisoft Store.",
    confirmText:
      "Estoy de acuerdo en que si quiero <0>cambiar la moneda de mi Wallet</0>, tendré que ponerme en contacto con Ubisoft y, al hacerlo, <1>perderé los fondos restantes</1> en mi Wallet.",
    buttonText: "Activar mi wallet",
    walletCreationError:
      "Se ha producido un error al recuperar la información de tu Wallet. Por favor, inténtalo de nuevo.",
    storeName: {
      kr: "República de Korea",
      tr: "Turquía",
      us: "Estados Unidos"
    }
  }
};
