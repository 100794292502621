import CoreMicroElement from "./core";
import retargetEvents from "react-shadow-dom-retarget-events";
import kebabCase from "lodash/kebabCase";
import packageJson from "../package";

class MicroElement extends CoreMicroElement {
  mount() {
    super.mount();
    retargetEvents(this.shadowRoot);
  }
}

const elementName = kebabCase(packageJson.name);
if (!customElements.get(elementName)) {
  customElements.define(elementName, MicroElement);
} else {
  console.error(
    `A custom element with the name "${elementName}" already exists in the page. We can't define it again.`
  );
}
