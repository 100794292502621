export default {
  translations: {
    walletCurrencyTitle:
      "Tu Cartera estará en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>y solo se puede usar en <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Tu Cartera está en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>y solo se puede usar en <1>{{storeName}} Ubisoft Store</1>",
    walletCurrencyTitle_sharedCurrency:
      "Tu Cartera está en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>y solo se puede usar en <1>store(s) donde los precios están establecidos {{currencyName}}</1>",
    geoRestrictedWarning:
      "Notamos que te encuentras en <0>{{countryName}}</0>, por lo tanto, no podrás usar tu Cartera en la Ubisoft Store.",
    confirmText:
      "Acepto que si quiero <0>cambiar la moneda de mi Cartera</0>, tendré que ponerme en contacto con Ubisoft y, al hacerlo, <1>perderé los fondos restantes</1> en mi Cartera.",
    buttonText: "Activar mi cartera",
    walletCreationError:
      "Hubo un error al confirmar tu Cartera. Vuelve a intentarlo más tarde.",
    storeName: {
      at: "Austria",
      br: "Brasil",
      ca: "Canada",
      cn: "China",
      de: "Alemania",
      es: "España",
      fr: "Francia",
      it: "Italia",
      jp: "Japón",
      kr: "Korea, Republic of",
      nl: "Holanda",
      ru: "Rusia",
      tr: "Turkey",
      uk: "Inglaterra",
      us: "United States",
      sea: "Sureste Asiático",
      eu: "Europa",
      ie: "internacional",
      anz: "Australia / Nueva Zelanda"
    }
  }
};
