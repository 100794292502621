export default {
  translations: {
    walletCurrencyTitle:
      "Votre Porte-monnaie sera en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>et seulement utilisable en <1>{{storeName}}</1>",
    walletCurrencyTitle_sharedCurrency:
      "Votre Porte-monnaie sera en <0>{{currencyName}} - {{currencySymbol}}</0> <br/>et utilisable uniquement sur <1>les magasin(s) où les prix sont fixés en {{currencyName}}</1>",
    geoRestrictedWarning:
      "Comme vous vous trouvez actuellement au <0>{{countryName}}</0>, vous ne pourrez pas utiliser votre Porte-monnaie sur le Ubisoft Store",
    storeName: {
      br: "Brésil",
      us: "US"
    }
  }
};
