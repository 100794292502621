export default {
  translations: {
    walletCurrencyTitle:
      "您的钱包将会使用币种： <0>{{currencyName}} - {{currencySymbol}}</0> <br/>仅限在 <1>{{storeName}}</1> 使用",
    walletCurrencyTitle_multiCountry:
      "您的币种将为<0>{{currencyName}} - {{currencySymbol}}</0> <br/>仅限在<1>{{storeName}} Ubisoft Store</1>使用",
    walletCurrencyTitle_sharedCurrency:
      "您的币种将为<0>{{currencyName}} - {{currencySymbol}}</0> <br/>仅限使用于<1>store(s) where prices are set in {{currencyName}}</1>",
    geoRestrictedWarning:
      "我们注意到您当前在 <0>{{countryName}}</0>，因此您无法在当前育碧商城使用钱包。",
    confirmText:
      "我同意若以后要<0>更改我的钱包币种</0>，则需要与育碧取得联系，并理解这样做会<1>丢失钱包中的所有剩余金额</1>。",
    buttonText: "激活我的钱包",
    walletCreationError: "在确认您的钱包时发生错误。请稍后再试。",
    storeName: {
      at: "Austria",
      br: "Brasil",
      ca: "Canada",
      cn: "China",
      de: "德国",
      es: "西班牙",
      fr: "法国",
      it: "意大利",
      jp: "日本",
      kr: "韩国",
      nl: "荷兰",
      ru: "俄罗斯",
      tr: "Turkey",
      uk: "英国",
      us: "United States",
      sea: "东南亚",
      eu: "欧洲",
      ie: "国际",
      anz: "澳大利亚/新西兰"
    }
  }
};
