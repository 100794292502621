const storeToCountry = {
  "br": [
    "BR",
    "OF",
    "CL",
    "CO",
    "CR",
    "AG",
    "BB",
    "BM",
    "BO",
    "BS",
    "BZ",
    "PY",
    "HT",
    "JM",
    "KN",
    "LC",
    "MX",
    "NI",
    "PA",
    "PE",
    "AR",
    "SR",
    "SV",
    "TT",
    "UY",
    "VC",
    "VE",
    "DM",
    "DO",
    "EC",
    "GD",
    "GT",
    "GY",
    "HN"
  ],
  "fr": [
    "FR",
    "CH",
    "BE",
    "LU",
    "MC"
  ],
  "cn": "CN",
  "us": [
    "US",
    "CU",
    "AI",
    "AN",
    "AS",
    "AW",
    "BL",
    "BQ",
    "KY",
    "MS",
    "PR",
    "SX",
    "TC",
    "UM",
    "VG",
    "VI",
    "CW",
    "FK",
    "GS",
    "GU"
  ],
  "ru": [
    "RU",
    "AM",
    "AZ",
    "BY",
    "KG",
    "KZ",
    "MD",
    "TJ",
    "TM",
    "UA",
    "UZ"
  ],
  "at": "AT",
  "anz": [
    "AU",
    "NZ"
  ],
  "sea": [
    "SG",
    "BN",
    "ID",
    "KH",
    "LA",
    "MM",
    "MO",
    "MY",
    "PH",
    "TH",
    "TL",
    "TW",
    "VN",
    "HK"
  ],
  "ca": "CA",
  "eu": [
    "SK",
    "PT",
    "HR",
    "HU",
    "IE",
    "PL",
    "SE",
    "EU",
    "CZ",
    "DK",
    "FI",
    "GR"
  ],
  "ie": [
    "ZW"
  ],
  "it": "IT",
  "jp": "JP",
  "nl": "NL",
  "uk": [
    "UK",
    "GB"
  ],
  "de": "DE",
  "es": "ES"
};

export const getGeoRedirectMapping = (storeCode) => {
  return storeToCountry[storeCode.toLowerCase()];
}

export default getGeoRedirectMapping;
