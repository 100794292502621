export default {
  translations: {
    walletCurrencyTitle:
      "Your Wallet will be in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>and only usable in <1>{{storeName}}</1>",
    walletCurrencyTitle_multiCountry:
      "Your Wallet will be in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>and only usable on the <1>{{storeName}} Ubisoft Store</1>",
    walletCurrencyTitle_sharedCurrency:
      "Your Wallet will be in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>and only usable on <1>store(s) where prices are set in {{currencyName}}</1>",
    geoRestrictedWarning:
      "We noticed that you are currently in <0>{{countryName}}</0>, therefore, you won't be able to use your Wallet on the current Ubisoft Store.",
    confirmText:
      "I agree that if I want to <0>change my Wallet currency</0>, I will need to contact Ubisoft and by doing so I will <1>lose any remaining funds</1> in my Wallet.",
    buttonText: "Activate my wallet",
    walletCreationError:
      "An error occurred while confirming your Wallet. Please try again later.",
    storeName: {
      at: "Austria",
      br: "Brasil",
      ca: "Canada",
      cn: "China",
      de: "Germany",
      es: "Spain",
      fr: "France",
      it: "Italy",
      jp: "Japan",
      kr: "Korea, Republic of",
      nl: "Netherlands",
      ru: "Russian Federation",
      tr: "Turkey",
      uk: "United Kingdom",
      us: "United States",
      sea: "South Asia",
      eu: "Europe",
      ie: "International",
      anz: "Australia-New Zealand"
    }
  }
};
