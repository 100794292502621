import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    initialized: false,
    loading: false,
    error: "",
    themeKey: "",
    locale: ""
  },
  reducers: {
    initialize(state, action) {
      state.initialized = true;
      state.themeKey = action.payload.themeKey;
      state.locale = action.payload.locale;
    },
    fetchStart(state, action) {
      state.loading = true;
    },
    fetchEnd(state, action) {
      state.loading = false;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state, action) {
      state.error = "";
    }
  }
});

export const {
  fetchStart,
  fetchEnd,
  setError,
  clearError,
  initialize
} = appSlice.actions;

export default appSlice.reducer;
