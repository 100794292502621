import appReducer from "../core/features/app/appSlice";
import { combineReducers } from "redux";
import userReducer from "../features/user/userSlice";
import walletReducer from "../features/wallet/walletSlice";

export default combineReducers({
  app: appReducer,
  user: userReducer,
  wallet: walletReducer
});
