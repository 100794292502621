// Libs
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
// Utils
import { getCurrencyName, getCurrencySymbol } from "@mooncake/utils";
// Components
import {
  Button,
  Checkbox,
  Container,
  Icon,
  Message,
  Text,
  useTheme
} from "@mooncake/ui";
import {
  BUTTON_ANIMATIONS,
  BUTTON_COLORS
} from "@mooncake/ui/lib/components/Button";
import AppLoader from "../AppLoader";
import { Warning } from "@mooncake/ui/lib/icons";
import { currencyStoreMapping } from "../../utils/currencyMapping";

const ModalContent = props => {
  const theme = useTheme();
  const i18n = useTranslation();
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const changeHandler = checked => {
    setCheckboxChecked(checked);
  };

  const currencyName = getCurrencyName(props.storeCurrency, props.locale);
  const currencySymbol = getCurrencySymbol(props.storeCurrency);

  const { countryName, isMultiCountry, storeName } = props;

  const isGeoRestricted = window.GeoRestrictedResources.GEORESTRICTED_REQUEST;

  let context;

  if (currencyStoreMapping[props.storeCurrency].length > 1) {
    context = "sharedCurrency";
  } else if (isMultiCountry) {
    context = "multiCountry";
  }

  return (
    <Container
      overflowX="hidden"
      overflowY="auto"
      padding={{
        default: "40px 16px 16px 16px",
        tablet: "40px"
      }}
      margin={"0 auto"}
      width={"100%"}
      maxWidth={"580px"}
      textAlign="center"
    >
      {props.loading ? (
        <Container height="120px">
          <AppLoader display={true} />
        </Container>
      ) : props.error ? (
        <React.Fragment>
          <Container
            marginBottom=".5em"
            fontSize={{
              default: "40px",
              tablet: "60px"
            }}
            lineHeight="1"
          >
            <Icon
              verticalAlign="top"
              icon={Warning}
              color={theme.colors.error}
            />
          </Container>
          <Text.Subtitle primary color={theme.colors.error}>
            {props.error}
          </Text.Subtitle>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text.Subtitle primary bold uppercase marginBottom="18px">
            <Trans
              i18nKey="walletCurrencyTitle"
              values={{
                context,
                currencyName,
                currencySymbol,
                storeName
              }}
            >
              <Text.Span color={theme.colors.primary.variant} />
              <Text.Span textDecoration={"underline"} />
            </Trans>
          </Text.Subtitle>
          {isGeoRestricted && (
            <Message
              type={"warning"}
              marginTop={"40px"}
              marginBottom={"25px"}
              textAlign={"left"}
            >
              <Text.Span secondary>
                <Trans i18nKey={"geoRestrictedWarning"}>
                  <Text.Span bold>{{ countryName }}</Text.Span>
                </Trans>
              </Text.Span>
            </Message>
          )}
          <Container maxWidth="430px" margin="0 auto">
            <Checkbox
              marginBottom="32px"
              onChange={changeHandler}
              alignItems="flex-start"
            >
              <Text.Span textAlign="left" secondary>
                <Trans i18nKey={"confirmText"}>
                  <Text.Span color={theme.colors.primary.variant} bold />
                  <Text.Span bold />
                </Trans>
              </Text.Span>
            </Checkbox>

            <Button
              animation={BUTTON_ANIMATIONS.invert}
              disabled={!checkboxChecked}
              outline={props.themeKey === "uplay"}
              color={BUTTON_COLORS.primary}
              onClick={props.onConfirm}
            >
              {i18n.t("buttonText")}
            </Button>
          </Container>
        </React.Fragment>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  error: state.app.error,
  loading: state.app.loading,
  locale: state.app.locale,
  themeKey: state.app.themeKey
});

export default connect(mapStateToProps)(ModalContent);
