import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Container, Loader } from "@mooncake/ui";

const AppLoader = props => {
  return props.display ? (
    <Container position="relative" height="100%">
      <Loader zIndex={11} />
    </Container>
  ) : null;
};

AppLoader.propTypes = {
  display: PropTypes.bool
};

const mapStateToProps = state => ({
  initialized: state.app.initialized
});

export default connect(mapStateToProps)(AppLoader);
