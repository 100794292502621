export default {
  translations: {
    walletCurrencyTitle:
      "Dein Wallet wird in <0>{{currencyName}} - {{currencySymbol}}</0>geführt <br/>und kann nur in <1>{{storeName}}</1> benutzt werden.",
    walletCurrencyTitle_multiCountry:
      "Dein Wallet wird in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>geführt und kann nur in <1>Ubisoft Store {{storeName}}</1> benutzt werden. ",
    walletCurrencyTitle_sharedCurrency:
      "Dein Wallet wird in <0>{{currencyName}} - {{currencySymbol}}</0> <br/>geführt und kann nur in <1>Stores benutzt werden, wo die Preise in {{currencyName}} geführt werden.</1>",
    geoRestrictedWarning:
      "Wir haben festgestellt, dass du dich derzeit in <0>{{countryName}}</0> befindest. Deshalb kannst du dein Wallet im aktuellen Ubisoft Store nicht verwenden.",
    confirmText:
      "Ich bin damit einverstanden, dass ich mich an Ubisoft wenden muss, wenn ich <0>die Währung meines Wallets ändern</0> möchte. Dadurch <1>verliere ich das restliche Budget</1> in meinem Wallet.",
    buttonText: "Mein Wallet aktivieren",
    walletCreationError:
      "Beim Bestätigen deines Wallets ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
    storeName: {
      at: "Österreich",
      br: "Brasilien",
      ca: "Kanada",
      cn: "China",
      de: "Deutschland",
      es: "Spanien",
      fr: "Frankreich",
      it: "Italien",
      jp: "Japan",
      kr: "Republik Korea",
      nl: "Niederlande",
      ru: "Russland",
      tr: "Türkei",
      uk: "England",
      us: "Vereinigtes Staaten",
      sea: "Südostasien",
      eu: "Europa",
      ie: "Internationaler Store",
      anz: "Australien / Neuseeland"
    }
  }
};
